import Highcharts from 'highcharts/highcharts';
import STRINGS from '~/lang/strings_el.json';
import { html, css, LitElement } from "lit";
import { classMap } from 'lit/directives/class-map.js';

export default class Utils {
    static amountColWidth=140;
    static round(num, scale) {
        if(!("" + num).includes("e")) {
            return +(Math.round(num + "e+" + scale)  + "e-" + scale);
        } else {
            var arr = ("" + num).split("e");
            var sig = ""
            if(+arr[1] + scale > 0) {
            sig = "+";
            }
            return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
        }
    }
    static getPolicyColors(id){
        let colors = {
            "4": "#29bba8",
            "7": "#9bca40",
            "8": "#f68946",
            "6": "#db5e5b",
            "5": "#07acda"
        };
        return colors[id];
    }
    static getFundsColors(id){
        let colors = {
            "610":"#344989",
            "612":"#da710f",
            "620":"#a654c6",
            "640":"#ed4985",
            "613":"#17becf",
            "614":"#bcbd22",
        };
        return colors[id];
    }
    static getCohesionColors(unit){
        let color="";
        switch(unit){
            case "AMOUNT_TOTAL":
                color="#ff6262";
                break;
            case "AMOUNT_EU":
                color="#3f48cc";
                break;
            case "AMOUNT_NATIONAL":
                color="#22b14c";
                break;
        }   
        return color;
    }
    static getHighchartColors(){
        let colors = ['#2caffe', '#544fc5', '#00e272', '#fe6a35', '#6b8abc', '#d568fb', '#2ee0ca', '#006400', '#feb56a', '#2f4f4f','#d7f50f','#91e8e1'];
        //colors = colors.unshift("#d11b2f");
        
        return Highcharts.map(colors, function (color) {
            return {
                radialGradient: {
                    cx: 0.5,
                    cy: 0.3,
                    r: 0.7
                },
                stops: [
                    [0, color],
                    [1, Highcharts.color(color).brighten(-0.3).get('rgb')] // darken
                ]
            };
        })
    }
    static getChartContainer(data,title){
        return html`
            <div class=${classMap(this.classes)} >
                <div class="header">
                    <div class="title">${title}</div>
                    <div class="actions">
                        <button @click='${this._download}'class="downloadBtn" aria-label="Download data"><i class="fa-solid fa-download"></i></button>
                    </div>
                </div>
                <div class="tab-content">
                    <div class="chartPane tab-pane active">
                        <div id='container'>${(!data)?STRINGS.MESSAGES.LOADING:""}</div>
                    </div>
                    <div class="tablePane tab-pane active">
                        <div id="gridContainer" part="thegrid" >
                        <data-table exportparts="innerrow: innerrow" id="grid" .options="${this.tableOptions}"></data-table>
                        </div>
                    </div>
                </div>
            </div>
        `;   
    }
    static getChartContainerSplit(data,title){
        return html`
            <div class=${classMap(this.classes)} >
                <div class="header">
                    <div class="title">${title}</div>
                    <div class="actions">
                        <button @click='${this._download}'class="downloadBtn" aria-label="Download data"><i class="fa-solid fa-download"></i></button>
                    </div>
                </div>
                <div class="tab-content">
                    <div class="chartPane tab-pane active">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-6">
                                    <div id='container1'>${(!data)?STRINGS.MESSAGES.LOADING:""}</div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-6">
                                    <div id='container2'>${(!data)?STRINGS.MESSAGES.LOADING:""}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tablePane tab-pane active">
                        <div id="gridContainer" >
                        <data-table id="grid" .options="${this.tableOptions}"></data-table>
                        </div>
                    </div>
                </div>
            </div>
        `;   
    }
}