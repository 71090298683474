import { css } from 'lit-element';
export default css`
.title{
    font-weight: bold;
    font-size: 20px;
    color: #fff;
}
.header{
    position: relative;
    padding-right: 75px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 7px;
    border-bottom: 1px solid #333;
    background: linear-gradient(90deg, rgba(15,80,142,1) 0%, rgba(30,192,235,1) 50%, rgba(126,196,82,1) 100%);
}
.header .actions{
    position: absolute;
    right: 0;
    top: 3px;
    padding: 5px 10px;
}
.wrapper{
    border: 1px solid #333;
    border-radius: 5px;
    margin: 0 0px;
    overflow: hidden;
}
.header .actions button{
    padding: 5px 10px;
}
.header .actions .fa-chart-simple{
    display:none;
}

.wrapper.switchedView .header .actions .fa-chart-simple{
    display: inline-block;
}
.wrapper.switchedView .header .actions .fa-table{
    display: none;
}
.wrapper .chartPane.tab-pane{
    display: block;
}
.wrapper .gridPane.tab-pane{
    display: none;
}
.wrapper.switchedView .chartPane.tab-pane{
    display: none;
}
.wrapper .gridPane.tab-pane{
    display: block;
}
.hTooltipHeader{
    font-weight: bold;
    margin-bottom: 5px;
    text-decoration: underline;
    font-size: 12px;
}
.hTooltipBody{
    
}

.tab-content {
  > .tab-pane {
    display: none;
  }
  > .active {
    display: block;
  }
}
.switchedView .tab-content {
    > .tab-pane {
      display: block;
    }
    > .active {
      display: none;
    }
  }
table.dataTable thead th {
    text-align: center !important;
}
.centeredHighchart{
    text-align: center;
}
op-tree::part(thegrid) .dt-control:before{
    content:"X";
    display: block;
    left:0;
    top:0;
}

@media (max-width:576px){
    #gridContainer{
        display: none;
    }
}
`;